import {
  UnorderedListOutlined,
  UploadOutlined,
  BlockOutlined,
  FileProtectOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "penawaran",
    path: `penawaran`,
    title: "Daftar Penawaran",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "quotation",
        path: `${ADMIN_PREFIX_PATH}/quotation`,
        title: "Non Material",
        // icon: FileTextOutlined,
        submenu: [],
      },
      {
        key: "quotation-material",
        path: `${ADMIN_PREFIX_PATH}/quotation-material`,
        title: "Material",
        // icon: FileTextOutlined,
        submenu: [],
      },
    ],
  },
  {
    key: "purchase-request",
    path: `purchase-request`,
    title: "Daftar PR",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "purchase-request",
        path: `${ADMIN_PREFIX_PATH}/purchase-request`,
        title: "Non Material",
        // icon: FileTextOutlined,
        submenu: [],
      },
      {
        key: "purchase-request-material",
        path: `${ADMIN_PREFIX_PATH}/purchase-request-material`,
        title: "Material",
        // icon: FileTextOutlined,
        submenu: [],
      },
    ],
  },
  {
    key: "penagihan",
    path: `penagihan`,
    title: "Penagihan",
    icon: BlockOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "penagihan-penagihan-berjalan",
        path: `${ADMIN_PREFIX_PATH}/penagihan-penagihan-berjalan`,
        title: "Penagihan Berjalan",
        icon: FileTextOutlined,
        submenu: [],
      },
      {
        key: "penagihan-upload-bukti-pembayaran",
        path: `${ADMIN_PREFIX_PATH}/penagihan-upload-bukti-pembayaran`,
        title: "Upload Bukti Pembayaran",
        icon: UploadOutlined,
        submenu: [],
      },
      {
        key: "penagihan-rekap-pembayaran",
        path: `${ADMIN_PREFIX_PATH}/penagihan-rekap-pembayaran`,
        title: "Rekap Pembayaran",
        icon: FileProtectOutlined,
        submenu: [],
      },
    ],
  },
];

const navigationNonAnggotaRequestConfig = [...dashBoardNavTree];

export default navigationNonAnggotaRequestConfig;
