import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationAdminConfig";
import navigationAllConfig from "configs/NavigationAdminAllConfig";
import navigationStnkConfig from "configs/NavigationAdminSTNKConfig";
import navigationNonAnggotaConfig from "configs/NavigationAdminNonAnggotaConfig";
import navigationMasterConfig from "configs/NavigationAdminMasterConfig";
import navigationCafeConfig from "configs/NavigationAdminCafeConfig";
import navigationNewsConfig from 'configs/NavigationAdminNewsConfig'
import axios from "axios";
import { env } from "configs/EnvironmentConfig";

import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import navigationNonAnggotaRequestConfig from "configs/NavigationAdminNonAnggotaRequestConfig";
import navigationNonAnggotaPenagihanConfig from "configs/NavigationAdminNonAnggotaPenagihanConfig";
import navigationNonAnggotaMasterConfig from "configs/NavigationAdminNonAnggotaMasterConfig";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const [stnk, setStnk] = useState(false);
  const [quotation, setQuotation] = useState(false);
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const getData = () => {
    axios
      .get(env.API + "/admin/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d == "MANAGE_STNK") {
            setStnk(true);
          }else{
            if(d == "MANAGE_QUOTATION"){
              setQuotation(true);
            }
          }
        });
      })
      .catch((e) => {});
  };
  useEffect(() => {
    // setError(true);

    // if (!data.length) {
    //  showToken(setTokenFound);
    getData();
    // }
  }, []);

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
      onClick={() => {
        const elem = document.getElementById("msg");
        if (elem !== undefined) {
          elem.style.display = "none";
        }
      }}
    >
      {stnk == false && quotation == false &&
      <p
        style={{
          fontSize: "11px",
          marginLeft: "10%",
          marginTop: "10px",
          marginBottom: "0px",
          fontWeight: "bold",
        }}
      >
        MANAGE PENGAJUAN
      </p>
      }
      {stnk == false && quotation == false &&
        navigationAllConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}
      {stnk == true &&
        navigationStnkConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}
    {quotation === true &&
      navigationNonAnggotaConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            title={menu.title}
            icon={menu.icon ? <menu.icon style={{ marginRight: 8 }} /> : null}
          >
            {menu.submenu.map((submenu) => (
              <Menu.Item key={submenu.key}>
                {submenu.icon ? <submenu.icon style={{ marginRight: 8 }} /> : null}
                <span style={{marginLeft:'13px'}}>{submenu.title}</span>
                {submenu.path ? (
                  <Link onClick={() => closeMobileNav()} to={submenu.path} />
                ) : null}
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <menu.icon /> : null}
            <span>{menu.title}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}

      {quotation === true &&
        <p
          style={{
            fontSize: "11px",
            marginLeft: "10%",
            marginTop: "20px",
            marginBottom: "0px",
            fontWeight: "bold",
          }}
        >
          MANAGE REQUEST
        </p>
        }
  {quotation === true &&
      navigationNonAnggotaRequestConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            title={menu.title}
            icon={menu.icon ? <menu.icon style={{ marginRight: 8 }} /> : null}
          >
            {menu.submenu.map((submenu) => (
              <Menu.Item key={submenu.key}>
                {submenu.icon ? <submenu.icon style={{ marginRight: 8 }} /> : null}
                <span style={{marginLeft:'13px'}}>{submenu.title}</span>
                {submenu.path ? (
                  <Link onClick={() => closeMobileNav()} to={submenu.path} />
                ) : null}
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <menu.icon /> : null}
            <span>{menu.title}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}

{quotation === true &&
        <p
          style={{
            fontSize: "11px",
            marginLeft: "10%",
            marginTop: "20px",
            marginBottom: "0px",
            fontWeight: "bold",
          }}
        >
          MANAGE PENAGIHAN
        </p>
        }
  {quotation === true &&
      navigationNonAnggotaPenagihanConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            title={menu.title}
            icon={menu.icon ? <menu.icon style={{ marginRight: 8 }} /> : null}
          >
            {menu.submenu.map((submenu) => (
              <Menu.Item key={submenu.key}>
                {submenu.icon ? <submenu.icon style={{ marginRight: 8 }} /> : null}
                <span style={{marginLeft:'13px'}}>{submenu.title}</span>
                {submenu.path ? (
                  <Link onClick={() => closeMobileNav()} to={submenu.path} />
                ) : null}
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <menu.icon /> : null}
            <span>{menu.title}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}

{quotation === true &&
        <p
          style={{
            fontSize: "11px",
            marginLeft: "10%",
            marginTop: "20px",
            marginBottom: "0px",
            fontWeight: "bold",
          }}
        >
          MASTER DATA
        </p>
        }
  {quotation === true &&
      navigationNonAnggotaMasterConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            title={menu.title}
            icon={menu.icon ? <menu.icon style={{ marginRight: 8 }} /> : null}
          >
            {menu.submenu.map((submenu) => (
              <Menu.Item key={submenu.key}>
                {submenu.icon ? <submenu.icon style={{ marginRight: 8 }} /> : null}
                <span style={{marginLeft:'13px'}}>{submenu.title}</span>
                {submenu.path ? (
                  <Link onClick={() => closeMobileNav()} to={submenu.path} />
                ) : null}
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <menu.icon /> : null}
            <span>{menu.title}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}

        
      {stnk == false && quotation == false &&
        navigationConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}
      {stnk == false && quotation == false && (
        <p
          style={{
            fontSize: "11px",
            marginLeft: "10%",
            marginTop: "20px",
            marginBottom: "0px",
            fontWeight: "bold",
          }}
        >
          MASTER DATA
        </p>
      )}

      {stnk == false && quotation == false &&
        navigationMasterConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}

{stnk == false && quotation == false && (
        <p
          style={{
            fontSize: "11px",
            marginLeft: "10%",
            marginTop: "20px",
            marginBottom: "0px",
            fontWeight: "bold",
          }}
        >
          ARTIKEL
        </p>
      )}

      {stnk == false && quotation == false &&
        navigationNewsConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}

      {stnk == false && quotation == false && (
        <p
          style={{
            fontSize: "11px",
            marginLeft: "10%",
            marginTop: "20px",
            marginBottom: "0px",
            fontWeight: "bold",
          }}
        >
          MANAGE CAFE
        </p>
      )}
      {stnk == false && quotation == false &&
        navigationCafeConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={menu.title}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={subMenuFirst.title}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>{subMenuSecond.title}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{subMenuFirst.title}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) => {
        return menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{menu.title}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) => {
              return subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={subMenuFirst.title}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{subMenuSecond.title}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{subMenuFirst.title} </span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              );
            })}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{menu?.title}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
