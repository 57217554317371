import {
  SettingOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  InboxOutlined,
  UploadOutlined,
  BlockOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  TagOutlined,
  DesktopOutlined
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard-non-anggota",
    path: `${ADMIN_PREFIX_PATH}/dashboard-non-anggota`,
    title: "Dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "quotation",
  //   path: `${ADMIN_PREFIX_PATH}/quotation`,
  //   title: "Daftar Penawaran",
  //   icon: UnorderedListOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "penawaran",
  //   path: `penawaran`,
  //   title: "Daftar Penawaran",
  //   icon: UnorderedListOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "quotation",
  //       path: `${ADMIN_PREFIX_PATH}/quotation`,
  //       title: "Non Material",
  //       // icon: FileTextOutlined,
  //       submenu: [],
  //     },
  //     {
  //       key: "quotation-material",
  //       path: `${ADMIN_PREFIX_PATH}/quotation-material`,
  //       title: "Material",
  //       // icon: FileTextOutlined,
  //       submenu: [],
  //     },
  //   ],
  // },
  // // {
  // //   key: "purchase-request",
  // //   path: `${ADMIN_PREFIX_PATH}/purchase-request`,
  // //   title: "Daftar Permintaan",
  // //   icon: UnorderedListOutlined,
  // //   breadcrumb: false,
  // //   submenu: [],
  // // },
  // {
  //   key: "purchase-request",
  //   path: `purchase-request`,
  //   title: "Daftar PR",
  //   icon: UnorderedListOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "purchase-request",
  //       path: `${ADMIN_PREFIX_PATH}/purchase-request`,
  //       title: "Non Material",
  //       // icon: FileTextOutlined,
  //       submenu: [],
  //     },
  //     {
  //       key: "purchase-request-material",
  //       path: `${ADMIN_PREFIX_PATH}/purchase-request-material`,
  //       title: "Material",
  //       // icon: FileTextOutlined,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   key: "monitoring-po",
  //   path: `${ADMIN_PREFIX_PATH}/monitoring-po`,
  //   title: "Monitoring No. PO",
  //   icon: DesktopOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "vendor-non-anggota",
  //   path: `${ADMIN_PREFIX_PATH}/vendor-non-anggota`,
  //   title: "Data Vendor",
  //   icon: UnorderedListOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "penerimaan-barang",
  //   path: `${ADMIN_PREFIX_PATH}/penerimaan-barang`,
  //   title: "Daftar GR",
  //   icon: InboxOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "penagihan",
  //   path: `penagihan`,
  //   title: "Penagihan",
  //   icon: BlockOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "penagihan-penagihan-berjalan",
  //       path: `${ADMIN_PREFIX_PATH}/penagihan-penagihan-berjalan`,
  //       title: "Penagihan Berjalan",
  //       icon: FileTextOutlined,
  //       submenu: [],
  //     },
  //     {
  //       key: "penagihan-upload-bukti-pembayaran",
  //       path: `${ADMIN_PREFIX_PATH}/penagihan-upload-bukti-pembayaran`,
  //       title: "Upload Bukti Pembayaran",
  //       icon: UploadOutlined,
  //       submenu: [],
  //     },
  //     {
  //       key: "penagihan-rekap-pembayaran",
  //       path: `${ADMIN_PREFIX_PATH}/penagihan-rekap-pembayaran`,
  //       title: "Rekap Pembayaran",
  //       icon: FileProtectOutlined,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   key: "pengaturan-divisi-kebutuhan-posisi",
  //   path: `${ADMIN_PREFIX_PATH}/pengaturan-divisi-kebutuhan-posisi`,
  //   title: "Divisi & Posisi",
  //   icon: SettingOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "surat-penjualam",
  //   path: `${ADMIN_PREFIX_PATH}/surat-penjualan`,
  //   title: "Surat Penjualan",
  //   icon: TagOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "pengaturan-anggota",
  //   path: `${ADMIN_PREFIX_PATH}/pengaturan-anggota`,
  //   title: "Pengaturan",
  //   icon: SettingOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
];

const navigationNonAnggotaConfig = [...dashBoardNavTree];

export default navigationNonAnggotaConfig;
