import {
  InboxOutlined,
  TagOutlined,
  DesktopOutlined
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [  
  {
    key: "monitoring-po",
    path: `${ADMIN_PREFIX_PATH}/monitoring-po`,
    title: "Monitoring No. PO",
    icon: DesktopOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "penerimaan-barang",
    path: `${ADMIN_PREFIX_PATH}/penerimaan-barang`,
    title: "Daftar GR",
    icon: InboxOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "surat-penjualam",
    path: `${ADMIN_PREFIX_PATH}/surat-penjualan`,
    title: "Rekap GR",
    icon: TagOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationNonAnggotaPenagihanConfig = [...dashBoardNavTree];

export default navigationNonAnggotaPenagihanConfig;
