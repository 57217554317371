import {
  SettingOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  InboxOutlined,
  UploadOutlined,
  BlockOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  TagOutlined,
  DesktopOutlined
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "vendor-non-anggota",
    path: `${ADMIN_PREFIX_PATH}/vendor-non-anggota`,
    title: "Data Vendor",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengaturan-divisi-kebutuhan-posisi",
    path: `${ADMIN_PREFIX_PATH}/pengaturan-divisi-kebutuhan-posisi`,
    title: "Divisi",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengaturan-anggota",
    path: `${ADMIN_PREFIX_PATH}/pengaturan-anggota`,
    title: "Pengaturan Email",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationNonAnggotaMasterConfig = [...dashBoardNavTree];

export default navigationNonAnggotaMasterConfig;
